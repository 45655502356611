import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

Sentry.init({
  dsn: 'https://759292fa9d6e4c06aad63f8519ca66a8@o512554.ingest.sentry.io/5613203',
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});
